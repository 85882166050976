import './table.css';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from './api/axios';
import { vehicleName } from './EditCargo';
import { Controller, useForm } from 'react-hook-form';
import { endOfDay, format, startOfDay } from 'date-fns';

const colors = {
  PUMIX_21: '#800000',
  PUMPA_KULSOS_RENDELVE: '#9A6324',
  PUMPA_KULSOS_RENDELNI: '#808000',
  MIXER: '#469990',
  DARU_KICSI: '#e6194B',
  DARU_NAGY: '#f58231',
  DARU_NAGY_POT: '#ffe119',
  BILLENCS: '#bfef45',
  KULSOS_FUVAR: '#911eb4',
  GYARI_FUVAR: '#f032e6',
  JON_ERTE: '#42d4f4',
};

const fontColors = {
  PUMIX_21: '#fff',
  PUMPA_KULSOS_RENDELVE: '#fff',
  PUMPA_KULSOS_RENDELNI: '#fff',
  MIXER: '#fff',
  DARU_KICSI: '#fff',
  DARU_NAGY: '#fff',
  DARU_NAGY_POT: '#000',
  BILLENCS: '#000',
  KULSOS_FUVAR: '#fff',
  GYARI_FUVAR: '#fff',
  JON_ERTE: '#000',
};

export const getCargos = async (query) => {
  if (query && Object.keys(query).length > 0) {
    const response = await axios(
      `/api/v1/cargos?${new URLSearchParams(query)}`
    );
    return response.data;
  }
  const response = await axios('/api/v1/cargos');
  return response.data;
};

const columnHelper = createColumnHelper();

const columns = [
  columnHelper.accessor('startDate', {
    cell: (info) => {
      const dateStr = info.getValue();
      if (!dateStr) {
        return '';
      }
      return new Intl.DateTimeFormat('hu', {
        dateStyle: 'medium',
        timeStyle: 'short',
      }).format(new Date(dateStr));
    },
    header: () => <span>Kezdő dátum</span>,
  }),
  columnHelper.accessor('endDate', {
    cell: (info) => {
      const dateStr = info.getValue();
      if (!dateStr) {
        return '';
      }
      return new Intl.DateTimeFormat('hu', {
        dateStyle: 'medium',
        timeStyle: 'short',
      }).format(new Date(dateStr));
    },
    header: () => <span>Záró dátum</span>,
  }),
  columnHelper.accessor('vehicle', {
    header: () => <span>Jármű</span>,
    cell: (info) => (
      <span
        style={{
          backgroundColor: colors[info.getValue()],
          color: fontColors[info.getValue()],
        }}
      >
        {vehicleName[info.getValue()]}
      </span>
    ),
  }),
  columnHelper.accessor('address', {
    header: () => <span>Cím</span>,
  }),
  columnHelper.accessor('phone', {
    header: () => <span>Telefonszám</span>,
  }),
  columnHelper.accessor('product', {
    header: () => <span>Termék</span>,
  }),
  columnHelper.accessor('paymentMethod', {
    header: () => <span>Fizetési mód</span>,
  }),
  columnHelper.accessor('price', {
    header: () => <span>Fizetendő összeg</span>,
  }),
  columnHelper.accessor('note', {
    header: () => <span>Egyéb megjegyzés</span>,
  }),
];

const now = new Date();
const nowDate = format(now, 'yyyy-MM-dd');
export default function Table() {
  const [query, setQuery] = useState({
    startDate: startOfDay(now).toISOString(),
    endDate: endOfDay(now).toISOString(),
  });
  const { isLoading, data } = useQuery(['cargos', query], () =>
    getCargos(query)
  );
  const { control, watch, handleSubmit, getValues } = useForm({
    defaultValues: {
      startDate: nowDate,
      endDate: nowDate,
      paymentMethod: '',
    },
  });

  const handleExportClick = async () => {
    const { startDate, endDate, vehicle } = getValues();
    const searchParams = new URLSearchParams();

    if (vehicle) {
      searchParams.set('vehicle', vehicle);
    }

    if (startDate && endDate) {
      searchParams.set('startDate', startDate);
      searchParams.set('endDate', endDate);
    }

    const response = await axios(`/api/v1/cargos/export?${searchParams}`, {
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'text/csv',
      },
    });
    const blob = await response.data;
    // Create blob link to download
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'export.csv');

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link?.parentNode?.removeChild(link);
  };

  const onSubmit = (data) => {
    let query = {
      ...(data.paymentMethod && { paymentMethod: data.paymentMethod }),
      ...(data.startDate &&
        data.endDate && {
          startDate: startOfDay(new Date(data.startDate)).toISOString(),
          endDate: endOfDay(new Date(data.endDate)).toISOString(),
        }),
    };
    setQuery(query);
  };

  useEffect(() => {
    const subscription = watch(handleSubmit(onSubmit));
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Box p={2} className="table">
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { mt: 1, mb: 1 },
          '& .MuiFormControl-root': { mt: 1, mb: 1 },
        }}
        noValidate
        autoComplete="off"
      >
        <Box paddingRight={2} display="inline-block">
          <Controller
            name="startDate"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                onChange={onChange}
                value={value}
                id="start-date"
                label="Kezdő dátum"
                type="date"
                sx={{ width: 250 }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Box>
        <Box paddingRight={2} display="inline-block">
          <Controller
            name="endDate"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                onChange={onChange}
                value={value}
                id="end-date"
                label="Záró dátum"
                type="date"
                sx={{ width: 250 }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Box>
        <Box display="inline-block">
          <FormControl sx={{ width: 250 }}>
            <InputLabel id="vehicle-select-label">Fizetési mód</InputLabel>
            <Controller
              name="vehicle"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  labelId="vehicle-select-label"
                  id="vehicle-select"
                  value={value}
                  label="Jármű"
                  onChange={onChange}
                >
                  <MenuItem value={'PUMIX_21'}>Pumix 21</MenuItem>
                  <MenuItem value={'PUMPA_KULSOS_RENDELVE'}>
                    Pumpa külsös rendelve
                  </MenuItem>
                  <MenuItem value={'PUMPA_KULSOS_RENDELNI'}>
                    Pumpa külsős rendelni
                  </MenuItem>
                  <MenuItem value={'MIXER'}>Mixer</MenuItem>
                  <MenuItem value={'DARU_KICSI'}>Daru kicsi</MenuItem>
                  <MenuItem value={'DARU_NAGY'}>Daru nagy</MenuItem>
                  <MenuItem value={'BILLENCS'}>Billencs</MenuItem>
                  <MenuItem value={'DARU_NAGY_POT'}>Daru nagy pót</MenuItem>
                  <MenuItem value={'KULSOS_FUVAR'}>Külsős fuvar</MenuItem>
                  <MenuItem value={'GYARI_FUVAR'}>Gyári fuvar</MenuItem>
                  <MenuItem value={'JON_ERTE'}>Jön érte</MenuItem>
                </Select>
              )}
            />
          </FormControl>
        </Box>
      </Box>
      <Button onClick={handleExportClick}>Export</Button>

      {isLoading ? (
        'Betöltés...'
      ) : (
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </Box>
  );
}
