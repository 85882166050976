import axios from "axios";

const onRequest = (config) => {
  const accessToken = localStorage.getItem("access_token")
  if (config.headers && accessToken) {
    config.headers["Authorization"] = `Bearer ${accessToken}`;
  }
  return config;
};

const onRequestError = (error) => {
  return Promise.reject(error);
};

const onResponse = (response) => {
  return response;
};

const onResponseError = async (error) => {
  if (error.response) {
    // Access Token was expired
    if (
      error.response.status === 401 &&
      error.response.data.message === 'TokenExpiredError'
    ) {
      const refreshToken = localStorage.getItem('refresh_token');

      try {
        const rs = await axios.post(
          "https://cetliapi.central-bau.hu/api/v1/auth/refreshToken",
          {
            refreshToken: refreshToken,
          }
        );

        const { accessToken, refreshToken: newRefreshToken } = rs.data;

        localStorage.setItem('access_token', accessToken);
        localStorage.setItem('refresh_token', newRefreshToken);

        return Promise.reject(error);
      } catch (_error) {
        return Promise.reject(_error);
      }
    }
  }
  return Promise.reject(error);
};

export const setupInterceptorsTo = (
  axiosInstance
) => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
};