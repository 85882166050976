import axios from './api/axios';

/**
 * This represents some generic auth provider API, like Firebase.
 */
const LOGIN_URL = 'api/v1/auth/login';

const fakeAuthProvider = {
  isAuthenticated: false,
  async signin(data, callback) {
    fakeAuthProvider.isAuthenticated = true;
    const response = await axios.post(
      LOGIN_URL,
      data,
      {
        headers: { 'Content-Type': 'application/json' },
      }
    );
    const accessToken = response?.data?.accessToken;
    const refreshToken = response?.data?.refreshToken;
    localStorage.setItem('access_token', accessToken);
    localStorage.setItem('refresh_token', refreshToken);
    callback()
  },
  signout(callback) {
    fakeAuthProvider.isAuthenticated = false;
     localStorage.removeItem('access_token');
     localStorage.removeItem('refresh_token');
     callback()
  },
};

export { fakeAuthProvider };
