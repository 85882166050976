import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Controller, useForm } from 'react-hook-form';
import { Alert, Box, Grid } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from './api/axios';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import Address from './Address'

export const vehicleName = {
  PUMIX_21: 'Pumix 21',
  PUMPA_KULSOS_RENDELVE: 'Pumpa külsös rendelve',
  PUMPA_KULSOS_RENDELNI: 'Pumpa külsős rendelni',
  MIXER: 'Mixer',
  DARU_KICSI: 'Daru kicsi',
  DARU_NAGY: 'Daru nagy',
  DARU_NAGY_POT: 'Daru nagy pót',
  BILLENCS: 'Billencs',
  KULSOS_FUVAR: 'Külsős fuvar',
  GYARI_FUVAR: 'Gyári fuvar',
  JON_ERTE: 'Jön érte',
};

function isElectron() {
  // Renderer process
  if (
    typeof window !== 'undefined' &&
    typeof window.process === 'object' &&
    window.process.type === 'renderer'
  ) {
    return true;
  }

  // Main process
  if (
    typeof process !== 'undefined' &&
    typeof process.versions === 'object' &&
    !!process.versions.electron
  ) {
    return true;
  }

  // Detect the user agent when the `nodeIntegration` option is set to true
  if (
    typeof navigator === 'object' &&
    typeof navigator.userAgent === 'string' &&
    navigator.userAgent.indexOf('Electron') >= 0
  ) {
    return true;
  }

  return false;
}

const postCargo = async (data) => {
  const response = await axios.post('/api/v1/cargos', data);
  return response.data;
};

const putCargo = async (id, data) => {
  const response = await axios.put(`/api/v1/cargos/${id}`, data);
  return response.data;
};

const deleteCargo = async (id) => {
  const response = await axios.delete(`/api/v1/cargos/${id}`);
  return response.data;
};

const getNotes = async (dateTime) => {
  const date = dateTime.split('T')[0]
  const response = await axios(`/api/v1/notes/${date}`);
  return response.data;
};

export default function EditCargo({
  open,
  onOpenChange,
  startDate,
  endDate,
  selectedCargo,
}) {
  const queryClient = useQueryClient();
  const { handleSubmit, reset, control, setValue, getValues } = useForm({
    defaultValues: {
      startDate: '',
      endDate: '',
      vehicle: '',
      address: '',
      phone: '',
      product: '',
      paymentMethod: '',
      price: '',
      note: '',
    },
  });


  const { isLoading: isNotesLoading, data: notes } = useQuery(
    ['notes', startDate, selectedCargo?.startDate],
    () => {
      return getNotes(selectedCargo?.startDate || startDate)},
    { enabled: !!startDate || (!!selectedCargo && !!selectedCargo.startDate) }
  );

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    reset();
    setDisabled(false);
    onOpenChange(false);
  };

  const handlePrint = () => {
    const data = getValues();
    const payload = {
      ...data,
      price: data.price ? parseInt(data.price, 10) : data.price,
    };
    window.electron.printCargo(selectedCargo ? selectedCargo : payload);
  };

  const handleEdit = () => {
    setDisabled(false);
  };

  const [disabled, setDisabled] = React.useState(false);

  const { mutate: createCargo, isLoading: isCreateLoading } = useMutation(
    postCargo,
    {
      onSuccess: (newCargo) => {
         queryClient.invalidateQueries(['events']);
        reset();
        setDisabled(false);
        onOpenChange(false);
      },
    }
  );

  const { mutate: updateCargo, isLoading: isUpdateLoading } = useMutation(
    ({id, data}) => putCargo(id, data),
    {
      onSuccess: (updatedCargo) => {
        queryClient.invalidateQueries(['events']);
        reset();
        setDisabled(false);
        onOpenChange(false);
      },
    }
  );

  const { mutate: deleteCargoMutate, isLoading: isDeleteLoading } = useMutation(
    () => deleteCargo(selectedCargo.id),
    {
      onSuccess: (deletedCargo) => {
        queryClient.invalidateQueries(['events']);
        reset();
        setDisabled(false);
        onOpenChange(false);
      },
    }
  );

  React.useEffect(() => {
    setValue('startDate', startDate);
    setValue('endDate', endDate);

    if (selectedCargo) {
      Object.entries(selectedCargo)
        .filter(([key, value]) => !!value)
        .filter(
          ([key, value]) =>
            key !== 'id' &&
            key !== 'userId' &&
            key !== 'updatedAt' &&
            key !== 'createdAt'
        )
        .forEach(([key, value]) => {
          setValue(key, value);
        });
      setDisabled(true);
    }
  }, [startDate, endDate, selectedCargo, open]);

 

  const onSubmit = (data) => {

    const payload = {
      ...data,
      price: data.price ? parseInt(data.price, 10) : null,
      startDate: data.startDate ? new Date(data.startDate).toISOString() : null,
      endDate: data.endDate ? new Date(data.endDate).toISOString() : null,
    };
    createCargo(payload);
  };

  const handleUpdate = () => {
    const data = getValues();
    const payload = {
      ...data,
      price: data.price ? parseInt(data.price, 10) : null,
      startDate: data.startDate
        ? new Date(data.startDate).toISOString()
        : null,
      endDate: data.endDate ? new Date(data.endDate).toISOString() : null,
    };
    updateCargo({ data: payload, id: selectedCargo.id });
  };

  const handleDelete = () => {
    deleteCargoMutate(selectedCargo.id);
  };

  return (
    <div>
      <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
        <DialogTitle>Új fuvar hozzáadása</DialogTitle>
        <Box paddingLeft={2} paddingRight={2}>
          {notes?.map((note) => (
            <Alert sx={{ mb: 1 }} key={note.id} severity="warning">
              {note.description}
            </Alert>
          ))}
        </Box>

        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { mt: 1, mb: 1 },
            '& .MuiFormControl-root': { mt: 1, mb: 1 },
          }}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
        >
          <DialogContent>
            {/* <DialogContentText>Info</DialogContentText> */}
            <Box paddingRight={2} display="inline-block">
              <Controller
                name="startDate"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    onChange={onChange}
                    value={value}
                    disabled={disabled}
                    id="start-date"
                    label="Kezdő dátum"
                    type="datetime-local"
                    sx={{ width: 250 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Box>
            <Controller
              name="endDate"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  onChange={onChange}
                  value={value}
                  disabled={disabled}
                  id="end-date"
                  label="Záró dátum"
                  type="datetime-local"
                  sx={{ width: 250 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
            <FormControl required fullWidth>
              <InputLabel id="car-select-label">Jármű</InputLabel>
              <Controller
                name="vehicle"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    labelId="car-select-label"
                    id="car-select"
                    value={value}
                    disabled={disabled}
                    label="Jármű"
                    onChange={onChange}
                  >
                    <MenuItem value={'PUMIX_21'}>Pumix 21</MenuItem>
                    <MenuItem value={'PUMPA_KULSOS_RENDELVE'}>
                      Pumpa külsös rendelve
                    </MenuItem>
                    <MenuItem value={'PUMPA_KULSOS_RENDELNI'}>
                      Pumpa külsős rendelni
                    </MenuItem>
                    <MenuItem value={'MIXER'}>Mixer</MenuItem>
                    <MenuItem value={'DARU_KICSI'}>Daru kicsi</MenuItem>
                    <MenuItem value={'DARU_NAGY'}>Daru nagy</MenuItem>
                    <MenuItem value={'BILLENCS'}>Billencs</MenuItem>
                    <MenuItem value={'DARU_NAGY_POT'}>Daru nagy pót</MenuItem>
                    <MenuItem value={'KULSOS_FUVAR'}>Külsős fuvar</MenuItem>
                    <MenuItem value={'GYARI_FUVAR'}>Gyári fuvar</MenuItem>
                    <MenuItem value={'JON_ERTE'}>Jön érte</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
            <Address
              disabled={disabled}
              control={control}
              setValue={setValue}
              getValues={getValues}
            ></Address>

            <Controller
              name="phone"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  required
                  onChange={onChange}
                  value={value}
                  disabled={disabled}
                  fullWidth
                  id="phone"
                  type="tel"
                  label="Telefonszám"
                  variant="outlined"
                />
              )}
            />
            <Controller
              name="product"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  required
                  onChange={onChange}
                  value={value}
                  disabled={disabled}
                  fullWidth
                  id="product"
                  label="Termék"
                  variant="outlined"
                  multiline
                  rows={4}
                />
              )}
            />
            <FormControl required fullWidth>
              <InputLabel id="payment-method-select-label">
                Fizetési mód
              </InputLabel>
              <Controller
                name="paymentMethod"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    labelId="payment-method-select-label"
                    id="payment-method-select"
                    value={value}
                    disabled={disabled}
                    label="Fizetési mód"
                    onChange={onChange}
                  >
                    <MenuItem value={'F'}>F</MenuItem>
                    <MenuItem value={'KF'}>KF</MenuItem>
                    <MenuItem value={'UTAL'}>Utal</MenuItem>
                    <MenuItem value={'BF'}>BF</MenuItem>
                    <MenuItem value={'TBF'}>TBF</MenuItem>
                    <MenuItem value={'TKF'}>TKF</MenuItem>
                    <MenuItem value={'TF'}>TF</MenuItem>
                    <MenuItem value={'SZALLITORA'}>Szállitóra</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
            <Controller
              name="price"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  onChange={onChange}
                  value={value}
                  disabled={disabled}
                  type="number"
                  id="price"
                  label="Fizetendő összeg"
                  variant="outlined"
                />
              )}
            />
            <Controller
              name="note"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  onChange={onChange}
                  value={value}
                  disabled={disabled}
                  id="note"
                  label="Egyéb megjegyzés"
                  variant="outlined"
                  multiline
                  rows={4}
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Mégsem</Button>
            {isElectron() && <Button onClick={handlePrint}>Nyomtatás</Button>}
            {selectedCargo && disabled && (
              <LoadingButton
                loading={isDeleteLoading}
                loadingPosition="start"
                startIcon={<DeleteIcon />}
                variant="outlined"
                onClick={handleDelete}
              >
                Törlés
              </LoadingButton>
            )}
            {disabled && selectedCargo && (
              <Button variant="contained" onClick={handleEdit}>
                Szerkesztés
              </Button>
            )}

            {!disabled && selectedCargo && (
              <LoadingButton
                loading={isUpdateLoading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
                onClick={handleUpdate}
              >
                Mentés
              </LoadingButton>
            )}
            {!disabled && (
              <LoadingButton
                loading={isCreateLoading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
                type="submit"
              >
                {selectedCargo ? 'Mentés másként' : 'Mentés'}
              </LoadingButton>
            )}
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
}
