// a little function to help us with reordering the result
function reorder(list, startIndex, endIndex) {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

export default reorder;

export const reorderQuoteMap = ({ eventsByDay, source, destination }) => {
  const sourceDay = eventsByDay.find(
    (item) => item.date === source.droppableId
  );
  const destinationDay = eventsByDay.find(
    (item) => item.date === destination.droppableId
  );

  const current = sourceDay
    ? sourceDay.events.filter((event) => !event.allDay)
    : [];
  const next = destinationDay
    ? destinationDay.events.filter((event) => !event.allDay)
    : [];
  const target = current[source.index];

  const currentNotes = sourceDay
    ? sourceDay.events.filter((event) => event.allDay)
    : [];
  const nextNotes = destinationDay
    ? destinationDay.events.filter((event) => event.allDay)
    : [];

  // moving to same list
  if (source.droppableId === destination.droppableId) {
    const reordered = reorder(current, source.index, destination.index);
    // return reordered.reduce(
    //   (acc, curr, index) => ({ ...acc, [curr.id]: index + 1 }),
    //   {}
    // );
    return eventsByDay.map((event) =>
      event.date === source.droppableId
        ? { date: event.date, events: [...currentNotes, ...reordered] }
        : event
    );
  }

  // moving to different list

  // remove from original
  current.splice(source.index, 1);
  // insert into next
  next.splice(destination.index, 0, {
    ...target,
    start: destination.droppableId,
    end: null,
    extendedProps: {
      ...target.extendedProps,
      startDate: destination.droppableId,
      endDate: null,
    },
  });

  // const currentEvents = current.reduce(
  //   (acc, curr, index) => ({ ...acc, [curr.id]: index + 1 }),
  //   {}
  // );
  // const nextEvents = next.reduce(
  //   (acc, curr, index) => ({ ...acc, [curr.id]: index + 1 }),
  //   {}
  // );
  // [source.droppableId]: current,
  // [destination.droppableId]: next,
  return eventsByDay.map((event) => {
    if (event.date === source.droppableId) {
      return { date: event.date, events: [...currentNotes, ...current] };
    }
    if (event.date === destination.droppableId) {
      return { date: event.date, events: [...nextNotes, ...next] };
    }
    return event;
  });
};

export function moveBetween({ list1, list2, source, destination }) {
  const newFirst = [...list1.values];
  const newSecond = [...list2.values];

  const moveFrom = source.droppableId === list1.id ? newFirst : newSecond;
  const moveTo = moveFrom === newFirst ? newSecond : newFirst;

  const [moved] = moveFrom.splice(source.index, 1);
  moveTo.splice(destination.index, 0, moved);

  return {
    list1: {
      ...list1,
      values: newFirst,
    },
    list2: {
      ...list2,
      values: newSecond,
    },
  };
}
