import format from 'date-fns/format';
import axios from './api/axios';

import './styles.css';

export const getEvents = async (start, end) => {
  const response = await axios(`/api/v1/calendar?start=${start}&end=${end}`);
  return response.data;
};

export const updateEventsOrder = async (data) => {
  const response = await axios.patch(`/api/v1/cargos/order`, data);
  return response.data;
};

export const formatDate = (start, end) => {
  const startDate = format(start, 'yyyy-MM-dd');
  const startTime = format(start, 'HH:mm');
  const startStr = `${startDate}T${startTime}`;
  if(!end) {
    return { start: startStr };
  }
  const endDate = format(end, 'yyyy-MM-dd');
  const endTime = format(end, 'HH:mm');
  const endStr = `${endDate}T${endTime}`;
  return { end: endStr, start: startStr };
};
