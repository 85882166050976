import React from 'react';
import axios from './api/axios';
import './table.css';
import { Chart } from 'react-charts';
import styled from '@emotion/styled';
import { useQuery } from '@tanstack/react-query';


const Container = styled.div`
  width: 100%;
  height: 600px;
  position: relative;
`;

export const getCargos = async () => {
  const response = await axios('/api/v1/cargos/statistics');
  return response.data;
};

export default function Table() {
  const { isLoading, data } = useQuery(
    ['cargos', 'statistics'],
    () => getCargos(),
    {
      select: (result) =>
        Object.entries(result).map(([key, value]) => ({
          label: key,
          data: value.map((item) => ({
            week: item.week,
            count: item.vehicle_count ?? 0,
          })),
        })),
    }
  );


  const primaryAxis = React.useMemo(
    () => ({
      getValue: (datum) => datum.week,
    }),
    []
  );

  const secondaryAxes = React.useMemo(
    () => [
      {
        getValue: (datum) => datum.count,
      },
    ],
    []
  );

  if(isLoading || !data) {
    return 'Loading...'
  }

  return (

      <Container>
        <Chart
          options={{
            data,
            primaryAxis,
            secondaryAxes,
          }}
        />
      </Container>

  );
}
