import React, { CSSProperties } from 'react';
import styled from '@emotion/styled';
import { N30, N40, N20, N0, G100, G200, N70, N900 } from '../primatives/colors';
import { borderRadius, grid } from '../constants';
import { vehicleName } from '../EditCargo';
import PlaceIcon from '@mui/icons-material/Place';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import NoteIcon from '@mui/icons-material/Note';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { IconButton } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from '../api/axios';
import { hu } from 'date-fns/locale';
import { format } from 'date-fns';

const getBackgroundColor = (
  isDragging,
  isGroupedOver,
  authorColors,
  status
) => {
  // if (isDragging) {
  //   return N40;
  // }

  // if (isGroupedOver) {
  //   return N30;
  // }
  if (status === 'DONE') {
    return N40;
  }

  return authorColors;
};

const getBorderColor = (isDragging, authorColors) =>
  isDragging ? authorColors : 'transparent';

const getTextColor = (isDragging, color) => color;

const imageSize = 40;

const CloneBadge = styled.div`
  background: ${G100};
  bottom: ${grid / 2}px;
  border: 2px solid ${G200};
  border-radius: 50%;
  box-sizing: border-box;
  font-size: 10px;
  position: absolute;
  right: -${imageSize / 3}px;
  top: -${imageSize / 3}px;
  transform: rotate(40deg);

  height: ${imageSize}px;
  width: ${imageSize}px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.a`
  border-radius: ${borderRadius}px;
  border: 2px solid transparent;
  /* border-color: ${(props) =>
    getBorderColor(props.isDragging, props.colors)}; */
  background-color: ${(props) =>
    getBackgroundColor(
      props.isDragging,
      props.isGroupedOver,
      props.colors,
      props.status
    )};
  box-shadow: 0px 1px 2px ${N70};
  box-sizing: border-box;
  padding: ${grid}px;
  min-height: ${imageSize}px;
  margin-bottom: ${grid}px;
  user-select: none;
  color: ${(props) => getTextColor(props.isDragging, props.textColor)};
  width: 234px;
  margin-right: 6px;

  &:hover,
  &:active {
    /* color: ${N900}; */
    text-decoration: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  /* flexbox */
  display: flex;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  line-height: 1;
  padding: 4px 0;
`;

const ItemText = styled.div`
  padding-left: 2px;
`;

const Content = styled.div`
  /* flex child */
  flex-grow: 1;

  /*
    Needed to wrap text in ie11
    https://stackoverflow.com/questions/35111090/why-ie11-doesnt-wrap-the-text-in-flexbox
  */
  flex-basis: 100%;

  /* flex parent */
  display: flex;
  font-size: 14px;
  flex-direction: column;
  position: relative;
`;

const BlockQuote = styled.div`
  &::before {
    content: open-quote;
  }

  &::after {
    content: close-quote;
  }
`;

const Footer = styled.div`
  display: flex;
  margin-top: ${grid}px;
  align-items: center;
`;

const Author = styled.small`
  color: ${(props) => props.colors.hard};
  flex-grow: 0;
  margin: 0;
  background-color: ${(props) => props.colors.soft};
  border-radius: ${borderRadius}px;
  font-weight: normal;
  padding: ${grid / 2}px;
`;

const ButtonCheck = styled(IconButton)`
  position: absolute;
  right: 0;
  padding: 0;
  top: 0;
  margin: 0;
`;

function getStyle(provided, style) {
  if (!style) {
    return provided.draggableProps.style;
  }

  return {
    ...provided.draggableProps.style,
    ...style,
  };
}

const updateCargoState = async ({ id, status }) => {
  const response = await axios.put(`/api/v1/cargos/state/${id}`, { status });
  return response.data;
};

// Previously this extended React.Component
// That was a good thing, because using React.PureComponent can hide
// issues with the selectors. However, moving it over does can considerable
// performance improvements when reordering big lists (400ms => 200ms)
// Need to be super sure we are not relying on PureComponent here for
// things we should be doing in the selector as we do not know if consumers
// will be using PureComponent
function QuoteItem(props) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: updateCargoState,
    onSettled: () => {
      queryClient.invalidateQueries(['events']);
    },
  });
  const {
    event,
    isDragging,
    isGroupedOver,
    provided,
    style,
    isClone,
    index,
    onClick,
  } = props;

  const handleCheck = (event, props) => {
    event.stopPropagation();
    const newState = props.status === 'TODO' ? 'DONE' : 'TODO';
    mutation.mutate({ id: props.id, status: newState });
  };


  const startDate = event.extendedProps.startDate
    ? format(new Date(event.extendedProps.startDate), 'HH:mm')
    : null;

  return (
    <Container
      onClick={onClick}
      isDragging={isDragging}
      isGroupedOver={Boolean(isGroupedOver)}
      colors={event.backgroundColor ?? '#3788d8'}
      textColor={
        event.extendedProps.status === 'DONE'
          ? '#000'
          : event.textColor ?? '#fff'
      }
      status={event.extendedProps.status}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={getStyle(provided, style)}
      data-is-dragging={isDragging}
      data-testid={event.id}
      data-index={index}
    >
      {isClone ? <CloneBadge>Clone</CloneBadge> : null}
      <Content>
        <ButtonCheck
          disableRipple={true}
          onClick={(e) => handleCheck(e, event.extendedProps)}
        >
          <CheckBoxIcon />
        </ButtonCheck>
        <Item>
          <PlaceIcon fontSize="14"></PlaceIcon>
          <ItemText>{event.extendedProps.address}</ItemText>
        </Item>
        <Item>
          <ShoppingCartIcon fontSize="14"></ShoppingCartIcon>
          <ItemText>{event.extendedProps.product}</ItemText>
        </Item>
        {startDate && startDate !== '00:00' && (
          <Item>
            <ShoppingCartIcon fontSize="14"></ShoppingCartIcon>
            <ItemText>{startDate}</ItemText>
          </Item>
        )}
        {event.extendedProps.note && (
          <Item>
            <ItemText>{event.extendedProps.note}</ItemText>
          </Item>
        )}
        <Item>
          <ItemText>{vehicleName[event.extendedProps.vehicle]}</ItemText>
        </Item>
      </Content>
    </Container>
  );
}

export default React.memo(QuoteItem);
