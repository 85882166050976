import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Global, css } from '@emotion/react';
import { B100, B200 } from '../primatives/colors';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import Column from './column';
import reorder, { reorderQuoteMap } from '../reorder';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { formatDate, getEvents, updateEventsOrder } from '../Calendar';
import {
  add,
  eachDayOfInterval,
  endOfWeek,
  format,
  isWithinInterval,
  startOfDay,
  startOfWeek,
  sub,
  subMilliseconds,
} from 'date-fns';
import { hu } from 'date-fns/locale';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Button, IconButton, Box } from '@mui/material';
import EditCargo from '../EditCargo';
import EditEvent from '../EditEvent';
import QuoteItem from '../primatives/quote-item';

const ParentContainer = styled.div`
  height: ${({ height }) => height};
  overflow-x: hidden;
  overflow-y: auto;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const DropZone = styled.div`
  min-height: 60px;
  background-color: rgb(243, 244, 246);
  padding: 8px;
  margin-left: 20px;
  margin-right: 20px;
  overflow: auto;

  display: flex;
  /*
    Needed to avoid growth in list due to lifting the first item
    Caused by display: inline-flex strangeness
  */
  align-items: start;
`;

const Title = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const ScrollContainer = styled.div`
  overflow: auto;
`;

const Container = styled.div`
  /* background-color: ${B100}; */
  min-height: 100vh;
  /* like display:flex but will allow bleeding over the window width */
  min-width: 100vw;
  display: inline-flex;
  width: 100%;
  overflow: scroll;
`;

const convertUTCDateToLocalDate = (date) => {
  var newDate = new Date(date);
  var offset = date.getTimezoneOffset() / 60;
  var hours = date.getHours();
  newDate.setHours(hours + offset);
  return newDate;
};

export const formatStartDate = (start) => {
  const startDate = format(start, 'yyyy-MM-dd');
  const startTime = format(start, 'HH:mm');
  const startStr = `${startDate}T${startTime}`;
  return startStr;
};

const formatAllDayDate = (start, end) => {
  const startDate = format(start, 'yyyy-MM-dd');
  const startStr = `${startDate}`;
  if (!end) {
    return { start: startStr };
  }
  const endDate = format(sub(end, { days: 1 }), 'yyyy-MM-dd');
  const endStr = `${endDate}`;
  return { end: endStr, start: startStr };
};

const Board = ({
  initial,
  containerHeight,
  useClone,
  isCombineEnabled = false,
  withScrollableColumns,
  applyGlobalStyles = true,
  autoScrollerOptions,
}) => {
  const [selectedWeek, setSelectedWeek] = useState({
    startDate: startOfWeek(new Date(), { locale: hu }),
    endDate: endOfWeek(new Date(), { locale: hu }),
  });
  const [startDate, setStartDate] = useState('');

  const [selectedCargo, setSelectedCargo] = React.useState();
  const [selectedEvent, setSelectedEvent] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [openEvent, setOpenEvent] = React.useState(false);

  const [eventsByDay, setEventsByDay] = useState([]);
  const [removedEvents, setRemovedEvents] = useState([]);

  const queryClient = useQueryClient();
  const { isLoading, data } = useQuery(
    ['events', selectedWeek],
    () =>
      getEvents(
        selectedWeek.startDate.toISOString(),
        selectedWeek.endDate.toISOString()
      ),
    {
      staleTime: Infinity,
      enabled: !!selectedWeek,
    }
  );

  useEffect(() => {
    if (!data) {
      return;
    }
    const removed = data.filter((item) => !item.start);
    setRemovedEvents(removed);
    const eventsByDate = data
      .filter((item) => !item.allDay)
      .filter((item) => item.start)
      .reduce((prev, next) => {
        const key = startOfDay(new Date(next.start)).toISOString();
        if (prev[key]) {
          return {
            ...prev,
            [key]: [...prev[key], next],
          };
        }
        return {
          ...prev,
          [key]: [next],
        };
      }, {});

    const notes = data
      .filter((item) => item.start)
      .filter((item) => item.allDay);
    const res = eachDayOfInterval({
      start: selectedWeek.startDate,
      end: selectedWeek.endDate,
    }).map((weekDay) => {
      const selectedNotes = notes.filter((note) => {
        const dateStart = convertUTCDateToLocalDate(new Date(note.start));
        const endStart = convertUTCDateToLocalDate(new Date(note.end));
        const result = isWithinInterval(weekDay, {
          start: dateStart,
          end: subMilliseconds(endStart, 1),
        });
        return result;
      });
      const weekDayStr = weekDay.toISOString();
      return eventsByDate[weekDayStr]
        ? {
            date: weekDayStr,
            events: [...selectedNotes, ...eventsByDate[weekDayStr]],
          }
        : { date: weekDayStr, events: [...selectedNotes] };
    });

    setEventsByDay(res);
  }, [data]);

  const mutation = useMutation({
    mutationFn: async ({ events }) => {
      await updateEventsOrder(events);
    },
  });

  const handleBeforeClick = () => {
    setSelectedWeek((currentSelectedWeek) => ({
      startDate: sub(currentSelectedWeek.startDate, { weeks: 1 }),
      endDate: sub(currentSelectedWeek.endDate, { weeks: 1 }),
    }));
  };

  const handleNextClick = () => {
    setSelectedWeek((currentSelectedWeek) => ({
      startDate: add(currentSelectedWeek.startDate, { weeks: 1 }),
      endDate: add(currentSelectedWeek.endDate, { weeks: 1 }),
    }));
  };

  const handleNowClick = () => {
    setSelectedWeek((currentSelectedWeek) => ({
      startDate: startOfWeek(new Date(), { locale: hu }),
      endDate: endOfWeek(new Date(), { locale: hu }),
    }));
  };

  const handleOpenChange = (value) => {
    setSelectedCargo(null);
    setOpen(value);
  };

  const handleAddClick = (date) => {
    setStartDate(formatStartDate(new Date(date)));
    setOpen(true);
  };

  const handleAddEventClick = (date) => {
    setStartDate(format(new Date(date), 'yyyy-MM-dd'));
    setOpenEvent(true);
  };

  const handleEventOpenChange = (value) => {
    setSelectedEvent(null);
    setOpenEvent(value);
  };

  const handleEventClick = (event) => {
    const extendedProps = event.extendedProps;

    if (!extendedProps.startDate) {
      setSelectedCargo({
        ...extendedProps,
        startDate: undefined,
        endDate: undefined,
      });
      setOpen(true);
      return;
    }

    if (extendedProps.startDate.includes('T00:00:00.000Z')) {
      const dateEnd = extendedProps.endDate
        ? new Date(extendedProps.endDate)
        : undefined;
      const { start, end } = formatAllDayDate(
        new Date(extendedProps.startDate),
        dateEnd
      );
      setSelectedEvent({ ...extendedProps, startDate: start, endDate: end });
      setOpenEvent(true);
    } else {
      const dateEnd = extendedProps.endDate
        ? new Date(extendedProps.endDate)
        : undefined;
      const { start, end } = formatDate(
        new Date(extendedProps.startDate),
        dateEnd
      );
      setSelectedCargo({ ...extendedProps, startDate: start, endDate: end });
      setOpen(true);
    }
  };

  const onDragEnd = (result) => {
    // dropped nowhere
    if (!result.destination) {
      return;
    }

    const source = result.source;
    const destination = result.destination;

    // did not move anywhere - can bail early
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    if (
      source.droppableId === 'removed' &&
      destination.droppableId === 'removed'
    ) {
      setRemovedEvents((currentRemoved) => {
        const reordered = reorder(
          currentRemoved,
          source.index,
          destination.index
        );
        mutation.mutate({
          events: reordered.reduce(
            (acc, curr, index) => ({
              ...acc,
              [curr.id]: { order: index + 1 },
            }),
            {}
          ),
        });
        return reordered;
      });
      return;
    }

    if (destination.droppableId === 'removed') {
      setRemovedEvents((currentRemoved) => {
        const sourceDay = eventsByDay.find(
          (item) => item.date === source.droppableId
        );
        const target = sourceDay.events.filter((event) => !event.allDay)[
          source.index
        ];
        const copy = [...currentRemoved];
        copy.splice(destination.index, 0, {
          ...target,
          start: null,
          end: null,
          extendedProps: {
            ...target.extendedProps,
            startDate: null,
            endDate: null,
          },
        });
        mutation.mutate({
          events: copy.reduce(
            (acc, curr, index) => ({
              ...acc,
              [curr.id]: { order: index + 1 },
            }),
            {}
          ),
        });
        return copy;
      });
      setEventsByDay((currentEventsByDay) => {
        const sourceDay = currentEventsByDay.find(
          (item) => item.date === source.droppableId
        );
        const target = sourceDay.events.filter((event) => !event.allDay)[
          source.index
        ];
        const currentNotes = sourceDay
          ? sourceDay.events.filter((event) => event.allDay)
          : [];
        const newEvents = sourceDay.events.filter(
          (event) => event.id !== target.id
        );

        mutation.mutate({ events: { [target.id]: { startDate: null, endDate: null } } });

        return eventsByDay.map((event) =>
          event.date === source.droppableId
            ? { date: event.date, events: [...currentNotes, ...newEvents] }
            : event
        );
      });
      return;
    }

    // start
    if (source.droppableId === 'removed') {
      setEventsByDay((currentEventsByDay) => {
        const sourceDay = currentEventsByDay.find(
          (item) => item.date === destination.droppableId
        );
        const target = removedEvents[source.index];

        const currentNotes = sourceDay
          ? sourceDay.events.filter((event) => event.allDay)
          : [];

        const currentEvents = sourceDay.events
          .filter((event) => !event.allDay)
          .filter((event) => event.id !== target.id);
        currentEvents.splice(destination.index, 0, {
          ...target,
          start: destination.droppableId,
          extendedProps: {
            ...target.extendedProps,
            startDate: destination.droppableId,
          },
        });

        mutation.mutate({
          events: currentEvents.reduce((acc, curr, index) => {
            if (curr.id === target.id) {
              return {
                ...acc,
                [curr.id]: {
                  order: index + 1,
                  startDate: destination.droppableId,
                },
              };
            }
            return {
              ...acc,
              [curr.id]: { order: index + 1 },
            };
          }, {}),
        });

        return eventsByDay.map((event) =>
          event.date === destination.droppableId
            ? { date: event.date, events: [...currentNotes, ...currentEvents] }
            : event
        );
      });
      setRemovedEvents((currentRemoved) => {
        const newRemoved = currentRemoved.filter(
          (item, index) => index !== source.index
        );
        return newRemoved;
      });
      return;
    }
    // end

    setEventsByDay((currentEventsByDay) => {
      const newEvents = reorderQuoteMap({
        eventsByDay: currentEventsByDay,
        source,
        destination,
      });

      if (source.droppableId === destination.droppableId) {
        const eventDay = newEvents.find(
          (event) => event.date === source.droppableId
        );
        const eventsWithOrder = eventDay.events
          .filter((event) => !event.allDay)
          .reduce(
            (acc, curr, index) => ({
              ...acc,
              [curr.id]: { order: index + 1 },
            }),
            {}
          );
        mutation.mutate({ events: eventsWithOrder });
      } else {
        const eventDay = newEvents.find(
          (event) => event.date === source.droppableId
        );
        const eventsWithOrder = eventDay.events
          .filter((event) => !event.allDay)
          .reduce(
            (acc, curr, index) => ({
              ...acc,
              [curr.id]: { order: index + 1 },
            }),
            {}
          );
        const eventDayNext = newEvents.find(
          (event) => event.date === destination.droppableId
        );
        const target = eventDayNext.events.filter((event) => !event.allDay)[
          destination.index
        ];

        const eventsWithOrderNext = eventDayNext.events
          .filter((event) => !event.allDay)
          .reduce((acc, curr, index) => {
            if (curr.id === target.id) {
              return {
                ...acc,
                [curr.id]: {
                  order: index + 1,
                  startDate: destination.droppableId,
                  endDate: null,
                },
              };
            }
            return {
              ...acc,
              [curr.id]: { order: index + 1 },
            };
          }, {});

        mutation.mutate({
          events: { ...eventsWithOrder, ...eventsWithOrderNext },
        });
      }

      return newEvents;
    });
    // mutate
    // mutation.mutate({events});
  };

  const startStr = format(selectedWeek.startDate, 'yyyy. MMMM d', {
    locale: hu,
  });
  const endStr = format(selectedWeek.endDate, 'd.', { locale: hu });

  const board = (
    <Container>
      {eventsByDay.map(({ date, events }, index) => (
        <Column
          key={date}
          index={index}
          title={date}
          events={events}
          isScrollable={withScrollableColumns}
          isCombineEnabled={isCombineEnabled}
          useClone={useClone}
          onEventSelected={handleEventClick}
          onAddClick={handleAddClick}
          onAddEventClick={handleAddEventClick}
        />
      ))}
    </Container>
  );

  return (
    <>
      <Header>
        <Box sx={{ padding: '8px' }}>
          <IconButton onClick={handleBeforeClick} aria-label="before">
            <NavigateBeforeIcon />
          </IconButton>
        </Box>
        <Box sx={{ padding: '8px' }}>
          <IconButton onClick={handleNextClick} aria-label="after">
            <NavigateNextIcon />
          </IconButton>
        </Box>
        <Box sx={{ padding: '8px' }}>
          <Button onClick={handleNowClick} variant="outlined">
            Ma
          </Button>
        </Box>
        <Title>
          <h1>
            {startStr} - {endStr}
          </h1>
        </Title>
      </Header>

      {isLoading ? (
        'Loading...'
      ) : (
        <DragDropContext
          onDragEnd={onDragEnd}
          autoScrollerOptions={autoScrollerOptions}
        >
          <h4 style={{ margin: '0 20px' }}>Fuvarok dátum nélkül</h4>
          <Droppable droppableId="removed" type="QUOTE" direction="horizontal">
            {(provided, snapshot) => (
              <DropZone ref={provided.innerRef} {...provided.droppableProps}>
                {removedEvents.map((event, index) => (
                  <Draggable
                    key={event.id}
                    draggableId={event.id}
                    index={index}
                  >
                    {(dragP, dragS) => (
                      <QuoteItem
                        key={event.id}
                        event={event}
                        onClick={() => handleEventClick(event)}
                        isDragging={dragS.isDragging}
                        isGroupedOver={Boolean(dragS.combineTargetFor)}
                        provided={dragP}
                      />
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </DropZone>
            )}
          </Droppable>
          {containerHeight ? (
            <ParentContainer height={containerHeight}>{board}</ParentContainer>
          ) : (
            board
          )}
        </DragDropContext>
      )}
      <EditCargo
        startDate={startDate}
        open={open}
        onOpenChange={handleOpenChange}
        selectedCargo={selectedCargo}
      ></EditCargo>
      <EditEvent
        startDate={startDate}
        endDate={startDate}
        open={openEvent}
        onOpenChange={handleEventOpenChange}
        selectedEvent={selectedEvent}
      ></EditEvent>
      {applyGlobalStyles ? (
        <Global
          styles={css`
            body {
              background: ${B200};
            }
          `}
        />
      ) : null}
    </>
  );
};

export default Board;
