import React, { Component } from 'react';
import styled from '@emotion/styled';
import { G50, N30 } from '../primatives/colors';
import { grid, borderRadius } from '../constants';
import QuoteList from '../primatives/quote-list';
import Title from '../primatives/title';
import { format } from 'date-fns';
import { hu } from 'date-fns/locale';
import { IconButton } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddBox';
import ReportIcon from '@mui/icons-material/Announcement';


const Container = styled.div`
  margin: ${grid}px;
  display: flex;
  flex-direction: column;
`;

const Note = styled.div`
  background-color: #3788d8;
  color: #fff;
  padding: 8px 12px;
  margin-bottom: 8px;
  border-radius: 8px;
`;



const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default class Column extends Component {
  render() {
    const title = this.props.title;
    const events = this.props.events.filter((event) => !event.allDay);
    const notes = this.props.events.filter(event => event.allDay);

    return (
      <Container>
        <Header>
          <Title>
            {format(new Date(title), 'MMMM d. EEEE', { locale: hu })}
          </Title>
          <IconButton
            onClick={() => this.props.onAddEventClick(title)}
            color="info"
          >
            <ReportIcon />
          </IconButton>
          <IconButton
            onClick={() => this.props.onAddClick(title)}
            color="primary"
          >
            <AddCircleIcon />
          </IconButton>
        </Header>
        {notes.map((note) => (
          <Note key={note.id} onClick={() => this.props.onEventSelected(note)}>
            {note.extendedProps.description}
          </Note>
        ))}
        <QuoteList
          listId={title}
          listType="QUOTE"
          events={events}
          internalScroll={this.props.isScrollable}
          isCombineEnabled={Boolean(this.props.isCombineEnabled)}
          useClone={Boolean(this.props.useClone)}
          onEventSelected={this.props.onEventSelected}
        />
      </Container>
    );
  }
}
