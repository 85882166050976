import axios from 'axios';
import { setupInterceptorsTo } from './interceptors';

const api = setupInterceptorsTo(
  axios.create({
    baseURL: "https://cetliapi.central-bau.hu",
    headers: {
      "Content-Type": "application/json",
    },
  })
);

export default api;
