import { Alert, AlertTitle } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import * as React from 'react';
import { Link } from 'react-router-dom';
import MuiLink from '@mui/material/Link';

export default function SignUpSuccess() {
  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box sx={{ mt: 3 }}>
          <Alert severity='success'>
            <AlertTitle>Sikeres regisztráció</AlertTitle>A Bejelentkezés az
            admin jóváhagyása után lehetséges
            <div>
              <Link to='/login' component={MuiLink} variant='body2'>
                Bejelentkezés
              </Link>
            </div>
          </Alert>
        </Box>
      </Box>
    </Container>
  );
}
