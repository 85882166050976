import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { debounce } from '@mui/material/utils';
import { Controller } from 'react-hook-form';
import axios from './api/axios';

export default function GoogleMaps({ control, disabled, setValue, getValues }) {
  // const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        axios(`/api/v1/cargos/address?query=${request.input}`).then((res) =>
          callback(res.data)
        );
      }, 400),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions([]);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        // if (value) {
        //   newOptions = [value];
        // }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetch]);

  return (
    <Controller
      name="address"
      control={control}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          fullWidth
          getOptionLabel={(option) =>
            typeof option === 'string' ? option : option.address
          }
          filterOptions={(x) => x}
          options={options}
          freeSolo
          disableClearable
          value={value}
          noOptionsText="Nincs találat"
          onChange={(event, newValue) => {
            setOptions(newValue ? [newValue, ...options] : options);
            const values = getValues();
            if (!values.phone) {
              setValue('phone', newValue.phone);
            }

            onChange(newValue.address);
          }}
          onInputChange={(event, newInputValue) => {
            onChange(newInputValue);
            setInputValue(newInputValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              disabled={disabled}
              label="Cím"
              fullWidth
            />
          )}
        />
      )}
    />
  );
}
