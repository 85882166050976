import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Controller, useForm } from 'react-hook-form';
import { Box } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from './api/axios';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { add } from 'date-fns';

const postNote = async (data) => {
  const response = await axios.post('/api/v1/notes', data);
  return response.data;
};

const putNote = async (id, data) => {
  const response = await axios.put(`/api/v1/notes/${id}`, data);
  return response.data;
};

const deleteNote = async (id) => {
  const response = await axios.delete(`/api/v1/notes/${id}`);
  return response.data;
};

export default function EditEvent({
  open,
  onOpenChange,
  startDate,
  endDate,
  selectedEvent,
}) {
  const queryClient = useQueryClient();
  const { handleSubmit, reset, control, setValue, getValues } = useForm({
    defaultValues: {
      startDate: '',
      endDate: '',
      description: '',
    },
  });

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return
    } 
    reset();
    setDisabled(false);
    onOpenChange(false);
  };

  const handleEdit = () => {
    setDisabled(false);
  };

  const [disabled, setDisabled] = React.useState(false);

  const { mutate: createNote, isLoading: isCreateLoading } = useMutation(
    postNote,
    {
      onSuccess: (newNote) => {
        queryClient.invalidateQueries(['events']);
        reset();
        setDisabled(false);
        onOpenChange(false);
      },
    }
  );

  const { mutate: updateNote, isLoading: isUpdateLoading } = useMutation(
    ({id, data}) => putNote(id, data),
    {
      onSuccess: (updatedNote) => {
        queryClient.invalidateQueries(['events']);
        reset();
        setDisabled(false);
        onOpenChange(false);
      },
    }
  );

  const { mutate: deleteNoteMutate, isLoading: isDeleteLoading } = useMutation(
    () => deleteNote(selectedEvent.id),
    {
      onSuccess: (deletedNote) => {
         queryClient.invalidateQueries(['events']);
        reset();
        setDisabled(false);
        onOpenChange(false);
      },
    }
  );

  React.useEffect(() => {
    setValue('startDate', startDate);
    setValue('endDate', endDate);

    if (selectedEvent) {
      Object.entries(selectedEvent)
        .filter(([key, value]) => !!value)
        .filter(
          ([key, value]) =>
            key !== 'id' &&
            key !== 'userId' &&
            key !== 'updatedAt' &&
            key !== 'createdAt'
        )
        .forEach(([key, value]) => {
          setValue(key, value);
        });
      setDisabled(true);
    }
  }, [startDate, endDate, selectedEvent, open]);


  const onSubmit = (data) => {
    const payload = {
      ...data,
      startDate: new Date(data.startDate).toISOString(),
      endDate: data.endDate ? add(new Date(data.endDate), { days: 1 }).toISOString(): undefined,
    };
    createNote(payload);
  };

  const handleUpdate = () => {
    const data = getValues();
    const payload = {
      ...data,
      startDate: new Date(data.startDate).toISOString(),
      endDate: data.endDate && add(new Date(data.endDate), { days: 1 }).toISOString(),
    };
    updateNote({ data: payload, id: selectedEvent.id });
  };

  const handleDelete = () => {
    deleteNoteMutate(selectedEvent.id);
  };

  return (
    <div>
      <Dialog fullWidth maxWidth='md' open={open} onClose={handleClose}>
        <DialogTitle>Új esemény hozzáadása</DialogTitle>
        <Box
          component='form'
          sx={{
            '& .MuiTextField-root': { mt: 1, mb: 1 },
            '& .MuiFormControl-root': { mt: 1, mb: 1 },
          }}
          noValidate
          autoComplete='off'
          onSubmit={handleSubmit(onSubmit)}
        >
          <DialogContent>
            {/* <DialogContentText>Info</DialogContentText> */}
            <Box paddingRight={2} display='inline-block'>
              <Controller
                name='startDate'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    onChange={onChange}
                    value={value}
                    disabled={disabled}
                    id='start-date'
                    label='Kezdő dátum'
                    type='date'
                    sx={{ width: 250 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Box>
            <Controller
              name='endDate'
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  onChange={onChange}
                  value={value}
                  disabled={disabled}
                  id='end-date'
                  label='Záró dátum'
                  type='date'
                  sx={{ width: 250 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
            <Controller
              name='description'
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  onChange={onChange}
                  value={value}
                  disabled={disabled}
                  id='description'
                  label='Leírás'
                  variant='outlined'
                  multiline
                  rows={4}
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Mégsem</Button>
            {disabled && selectedEvent && (
              <Button variant='contained' onClick={handleEdit}>
                Szerkesztés
              </Button>
            )}
            {selectedEvent && disabled && (
              <LoadingButton
                loading={isDeleteLoading}
                loadingPosition='start'
                startIcon={<SaveIcon />}
                variant='outlined'
                onClick={handleDelete}
              >
                Törlés
              </LoadingButton>
            )}
            {!disabled && selectedEvent && (
              <LoadingButton
                loading={isUpdateLoading}
                loadingPosition='start'
                startIcon={<SaveIcon />}
                variant='outlined'
                onClick={handleUpdate}
              >
                Mentés
              </LoadingButton>
            )}
            {!disabled && (
              <LoadingButton
                loading={isCreateLoading}
                loadingPosition='start'
                startIcon={<SaveIcon />}
                variant='contained'
                type='submit'
              >
                {selectedEvent ? 'Mentés másként' : 'Mentés'}
              </LoadingButton>
            )}
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
}
