import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import MuiLink from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Link, Navigate } from 'react-router-dom';
import axios from './api/axios';
import { Alert, AlertTitle } from '@mui/material';

function Copyright(props) {
  return (
    <Typography
      variant='body2'
      color='text.secondary'
      align='center'
      {...props}
    >
      {'Copyright © '}
      <MuiLink color='inherit' href='https://www.central-bau.hu/'>
        Central Bau
      </MuiLink>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function SignUp() {
  const [errorMsg, setErrorMsg] = React.useState('');
  const [user, setUser] = React.useState('');
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email');
    const password = data.get('password');
    const firstName = data.get('firstName');
    const lastName = data.get('lastName');
    const payload = { email, password, firstName, lastName };

    try {
      const response = await axios.post('api/v1/auth/register', payload, {
        headers: { 'Content-Type': 'application/json' },
      });
      setUser(response.data.user);
    } catch (error) {
      if (
        error.response.data.message ===
        'You must provide an email and a password.'
      ) {
        setErrorMsg('Minden mező kitöltése kötelező.');
      } else if (error.response.data.message === 'Email already in use.') {
        setErrorMsg('Az e-mail-cím már használatban van.');
      } else {
        setErrorMsg('Hiba történt');
      }
    }
  };

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      {user && <Navigate to='/register-success' replace={true} />}
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          Regisztráció
        </Typography>
        <Box component='form' noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete='given-name'
                name='firstName'
                required
                fullWidth
                id='firstName'
                label='Vezetéknév'
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id='lastName'
                label='Keresztnév'
                name='lastName'
                autoComplete='family-name'
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id='email'
                label='E-mail Cím'
                name='email'
                autoComplete='email'
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name='password'
                label='Jelszó'
                type='password'
                id='password'
                autoComplete='new-password'
              />
            </Grid>
            {errorMsg && (
              <Grid item xs={12}>
                <Alert severity='error'>
                  <AlertTitle>Hiba</AlertTitle>
                  {errorMsg}
                </Alert>
              </Grid>
            )}
          </Grid>
          <Button
            type='submit'
            fullWidth
            variant='contained'
            sx={{ mt: 3, mb: 2 }}
          >
            Regisztráció
          </Button>
          <Grid container justifyContent='flex-end'>
            <Grid item>
              <Link to='/login' component={MuiLink} variant='body2'>
                Bejelentkezés
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: 5 }} />
    </Container>
  );
}
