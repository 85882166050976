import {
  QueryClient,
  QueryClientProvider,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import * as React from 'react';
import {
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
  Navigate,
  Outlet,
} from 'react-router-dom';
import { fakeAuthProvider } from './auth';
import SignIn from './SignIn';
import SignUp from './SignUp';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import SignUpSuccess from './SignUpSuccess';
import {
  AppBar,
  Avatar,
  Button,
  CssBaseline,
  GlobalStyles,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material';
import MuiLink from '@mui/material/Link';
import axios from './api/axios';
import Logout from '@mui/icons-material/Logout';
import Table from './Table';
import Board from './board/board';
import Statistics from './statistics';
import Maps from './maps';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <AuthProvider>
        <Routes>
          <Route path="/login" element={<SignIn />} />
          <Route path="/register" element={<SignUp />} />
          <Route path="/register-success" element={<SignUpSuccess />} />
          <Route element={<Layout />}>
            <Route
              path="/"
              element={
                <RequireAuth>
                  <Board applyGlobalStyles={false} />
                </RequireAuth>
              }
            />
            <Route
              path="/table"
              element={
                <RequireAuth>
                  <Table />
                </RequireAuth>
              }
            />
            <Route
              path="/statistics"
              element={
                <RequireAuth>
                  <Statistics />
                </RequireAuth>
              }
            />
            <Route
              path="/maps"
              element={
                <RequireAuth>
                  <Maps />
                </RequireAuth>
              }
            />
          </Route>
        </Routes>
      </AuthProvider>
    </QueryClientProvider>
  );
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: '#66cc02',
      marginLeft: '8px',
    },
    children: name,
  };
}

function Layout() {
  let auth = useAuth();
  const queryClient = useQueryClient();

  const userName = auth.user
    ? auth.user.lastName.charAt(0) + auth.user.firstName.charAt(0)
    : '';
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    auth.signout(() => {
      setAnchorEl(null);
      queryClient.invalidateQueries(['profile']);
    });
  };

  return (
    <>
      <GlobalStyles
        styles={{
          ul: { margin: 0, padding: 0, listStyle: 'none' },
          html: {
            height: '100%',
          },
          body: {
            height: '100%',
          },
        }}
      />
      <CssBaseline />
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
        <Toolbar sx={{ flexWrap: 'wrap' }}>
          <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            Central Bau
          </Typography>
          <nav>
            <MuiLink
              to="/"
              component={Link}
              variant="button"
              color="text.primary"
              href="#"
              sx={{ my: 1, mx: 1.5 }}
            >
              Cetlik
            </MuiLink>
            <MuiLink
              to="/table"
              component={Link}
              variant="button"
              color="text.primary"
              href="#"
              sx={{ my: 1, mx: 1.5 }}
            >
              Táblázat
            </MuiLink>
            <MuiLink
              to="/statistics"
              component={Link}
              variant="button"
              color="text.primary"
              href="#"
              sx={{ my: 1, mx: 1.5 }}
            >
              Statisztika
            </MuiLink>
            <MuiLink
              to="/maps"
              component={Link}
              variant="button"
              color="text.primary"
              href="#"
              sx={{ my: 1, mx: 1.5 }}
            >
              Térkép
            </MuiLink>
          </nav>
          <Avatar onClick={handleClick} {...stringAvatar(userName)} />
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 23,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
          >
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Kijelentkezés
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Outlet />
    </>
  );
}

let AuthContext = React.createContext(null);

function AuthProvider({ children }) {
  let [user, setUser] = React.useState(null);

  let signin = (newUser, callback) => {
    return fakeAuthProvider.signin(newUser, () => {
      callback();
    });
  };

  let refresh = (user) => {
    setUser(user);
  };

  let signout = (callback) => {
    return fakeAuthProvider.signout(() => {
      setUser(null);
      callback();
    });
  };

  let value = { user, signin, signout, refresh };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return React.useContext(AuthContext);
}

const getProfile = async (data) => {
  const response = await axios('/api/v1/users/profile', data);
  return response.data;
};

function RequireAuth({ children }) {
  const auth = useAuth();
  const { isLoading, isError } = useQuery(['profile'], getProfile, {
    onSuccess: (user) => {
      auth.refresh(user);
    },
  });
  let location = useLocation();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <Navigate to='/login' state={{ from: location }} replace />;
  }

  return children;
}
