import React, { CSSProperties, ReactElement } from 'react';
import styled from '@emotion/styled';
import { R50, T50, N30 } from '../primatives/colors';
import { Droppable, Draggable } from '@hello-pangea/dnd';
import QuoteItem from './quote-item';
import { grid } from '../constants';
import Title from './title';

export const getBackgroundColor = (
  isDraggingOver,
  isDraggingFrom
) => {
  if (isDraggingOver) {
    return R50;
  }
  if (isDraggingFrom) {
    return T50;
  }
  return N30;
};

const Wrapper = styled.div`
  background-color: rgb(243, 244, 246);
  display: flex;
  flex-direction: column;
  opacity: ${({ isDropDisabled }) => (isDropDisabled ? 0.5 : 'inherit')};
  padding: ${grid}px;
  border: ${grid}px;
  padding-bottom: 0;
  transition: background-color 0.2s ease, opacity 0.1s ease;
  user-select: none;
  width: 250px;
`;

const scrollContainerHeight = 250;

const DropZone = styled.div`
  /* stop the list collapsing when empty */
  min-height: ${scrollContainerHeight}px;

  /*
    not relying on the items for a margin-bottom
    as it will collapse when the list is empty
  */
  padding-bottom: ${grid}px;
`;

const ScrollContainer = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  max-height: ${scrollContainerHeight}px;
`;

/* stylelint-disable block-no-empty */
const Container = styled.div``;

/* stylelint-enable */

function InnerQuoteList(props) {
  return (
    <>
      {props.events.map((event, index) => (
        <Draggable key={event.id} draggableId={event.id} index={index}>
          {(dragProvided, dragSnapshot) => (
            <QuoteItem
              key={event.id}
              event={event}
              onClick={() => props.onEventSelected(event)}
              isDragging={dragSnapshot.isDragging}
              isGroupedOver={Boolean(dragSnapshot.combineTargetFor)}
              provided={dragProvided}
            />
          )}
        </Draggable>
      ))}
    </>
  );
}

const InnerQuoteListMemo = React.memo(InnerQuoteList);

function InnerList(props) {
  const { events, dropProvided } = props;
  const title = props.title ? <Title>{props.title}</Title> : null;

  return (
    <Container>
      {title}
      <DropZone ref={dropProvided.innerRef}>
        <InnerQuoteListMemo
          onEventSelected={props.onEventSelected}
          events={events}
        />
        {dropProvided.placeholder}
      </DropZone>
    </Container>
  );
}

export default function QuoteList(props) {
  const {
    ignoreContainerClipping,
    internalScroll,
    scrollContainerStyle,
    isDropDisabled,
    isCombineEnabled,
    listId = 'LIST',
    listType,
    style,
    events,
    title,
    useClone,
    onEventSelected,
  } = props;

  return (
    <Droppable
      droppableId={listId}
      type={listType}
      ignoreContainerClipping={ignoreContainerClipping}
      isDropDisabled={isDropDisabled}
      isCombineEnabled={isCombineEnabled}
      renderClone={
        useClone
          ? (provided, snapshot, descriptor) => (
              <QuoteItem
                quote={events[descriptor.source.index]}
                provided={provided}
                isDragging={snapshot.isDragging}
                isClone
              />
            )
          : undefined
      }
    >
      {(dropProvided, dropSnapshot) => (
        <Wrapper
          style={style}
          isDraggingOver={dropSnapshot.isDraggingOver}
          isDropDisabled={Boolean(isDropDisabled)}
          isDraggingFrom={Boolean(dropSnapshot.draggingFromThisWith)}
          {...dropProvided.droppableProps}
        >
          {internalScroll ? (
            <ScrollContainer style={scrollContainerStyle}>
              <InnerList
                events={events}
                title={title}
                dropProvided={dropProvided}
                onEventSelected={onEventSelected}
              />
            </ScrollContainer>
          ) : (
            <InnerList
              events={events}
              title={title}
              dropProvided={dropProvided}
              onEventSelected={onEventSelected}
            />
          )}
        </Wrapper>
      )}
    </Droppable>
  );
}
