import React, { useEffect, useState } from 'react';
import axios from './api/axios';
import styled from '@emotion/styled';
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from '@react-google-maps/api';
import { useQuery } from '@tanstack/react-query';
import { TextField } from '@mui/material';
import { endOfDay, format, startOfDay } from 'date-fns';

const containerStyle = {
  width: '100%',
  height: 'calc(100% - 141px)',
};

const StyledTextField = styled(TextField)`
  margin: 10px;
`

const center = {
  lat: 47.2437333,
  lng: 18.6908323,
};

export const getCargos = async (date) => {
  const start = startOfDay(new Date(date));
  const end = endOfDay(new Date(date));
  const response = await axios(
    `/api/v1/cargos/map?startDate=${start.toISOString()}&endDate=${end.toISOString()}`
  );
  return response.data;
};

function MyComponent({ data }) {
  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const handleOnLoad = (map) => {
    const bounds = new google.maps.LatLngBounds();
    [center, ...data].forEach(({ lat, lng }) => bounds.extend({ lat, lng }));
    map.fitBounds(bounds);
  };
  return (
    <LoadScript googleMapsApiKey="AIzaSyA65aQBhUhP9D9iWW16_VeXB-F7bMWRoOk">
      <GoogleMap
        onLoad={handleOnLoad}
        onClick={() => setActiveMarker(null)}
        mapContainerStyle={containerStyle}
      >
        {/* Child components, such as markers, info windows, etc. */}
        <Marker
          icon={{
            path: 'M8 12l-4.7023 2.4721.898-5.236L.3916 5.5279l5.2574-.764L8 0l2.3511 4.764 5.2574.7639-3.8043 3.7082.898 5.236z',
            fillColor: 'yellow',
            fillOpacity: 0.9,
            scale: 2,
            strokeColor: 'gold',
            strokeWeight: 2,
          }}
          position={center}
        />
        {data.map((item) => (
          <Marker
            key={item.id}
            icon={{
              path: 'M8 12l-4.7023 2.4721.898-5.236L.3916 5.5279l5.2574-.764L8 0l2.3511 4.764 5.2574.7639-3.8043 3.7082.898 5.236z',
              fillColor: item.backgroundColor,
              fillOpacity: 0.9,
              scale: 2,
              strokeColor: item.backgroundColor,
              strokeWeight: 2,
            }}
            onClick={() => handleActiveMarker(item.id)}
            position={{
              lat: item.lat,
              lng: item.lng,
            }}
          >
            {activeMarker === item.id ? (
              <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                <>
                  <div>{item.address}</div>
                  <div>{item.product}</div>
                  <div>{item.vehicle}</div>
                  <div>{item.note}</div>
                </>
              </InfoWindow>
            ) : null}
          </Marker>
        ))}
      </GoogleMap>
    </LoadScript>
  );
}

const MemoMyComponent = React.memo(MyComponent);

export default function Maps() {
  
  const [value, setValue] = useState();
  const { isLoading, data } = useQuery(['cargos', 'maps', value], () =>
    getCargos(value),
    {enabled: !!value}
  );

  useEffect(() => {
    const now = format(new Date(), 'yyyy-MM-dd');
    setValue(now);
  }, [])

  if (isLoading || !data) {
    return 'Loading...';
  }

  return (
    <>
      <StyledTextField
        onChange={(event) => {
          setValue(event.target.value);
        }}
        value={value}
        id="start-date"
        label="Dátum"
        type="date"
        sx={{ width: 250 }}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <MemoMyComponent data={data} />
    </>
  );
}
