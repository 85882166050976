import styled from '@emotion/styled';
import { P100 } from './colors';
import { grid } from '../constants';

export default styled.h4`
  padding: 4px;
  margin: 0;
  flex-grow: 1;
  text-align: center;
  user-select: none;
  position: relative;

  &:focus {
    outline: 2px solid ${P100};
    outline-offset: 2px;
  }
`;
